// Components
import { Button } from "components/core/buttons"


// API
import { updateIncident } from "api/incidents";

// Icons
import { GoIssueClosed as ClosedIcon, GoIssueReopened as ReopenIcon } from "react-icons/go"

// Hooks
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useIncident } from "hooks/useIncident";
import { useIncidents } from "hooks/useIncidents";
import { useAPI } from "hooks/useAPI";
import { usePostTimelineEvent } from "components/incidents/timeline/events";

export function ToggleStatus() {
    const { t } = useTranslation("common")
    const { incident: incidentParams } = useParams<{ incident: string }>()
    const { incident, setIncident } = useIncident()
    const { execute:reloadIncidents } = useIncidents()
    const status = incident?.status?.toLowerCase()
    const messageType = status === "open" ? "incident_closed" : "incident_reopened"

    const onUpdateStatusResult = useCallback((result: any) => {
        executePostEvent()
        
        setIncident((prev: any) => {
            if (result.status.toLowerCase() === "closed") {
                return { ...prev, status: result.status, progress: 100 }
            }
            return { ...prev, status: result.status }
        })

        reloadIncidents?.()

    }, [messageType])
    
    const { execute: executePostEvent } = usePostTimelineEvent(incidentParams || "", messageType)
    const [, { execute: executeUpdateStatus, loading: loadingUpdateStatus }] = useAPI(updateIncident, { incidentId: incidentParams, status: incident?.status?.toLowerCase() === "open" ? "closed" : "open" }, { immediate: false, onResult: onUpdateStatusResult, errorToastMessage: t("error-updating-status"), successToastMessage: t("success-update-status") })

    return <>
        {
            <>
                <Button disabled={!incident || loadingUpdateStatus} onClick={executeUpdateStatus} theme="blank" className="flex items-center justify-center w-full h-10 px-3 py-2 leading-none border rounded-md hover:cursor-pointer hover:bg-opacity-20 border-gray-400/20 sm:w-fit bg-container-light">
                    {
                        status === "open" ?
                            <>
                                <ClosedIcon className="mr-2 text-lg text-purple-400" />{t("close-issue")}
                            </>
                            :
                            <>
                                <ReopenIcon className="mr-2 text-xl text-primary-light" />{t("reopen-issue")}
                            </>
                    }
                </Button>
            </>
        }
    </>
}