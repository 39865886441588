// Components
import { Container, ContainerHeader } from "components/core/container"
import { Spinner } from "components/core/spinner"
import { Paginate } from "components/core/paginate"
import { Link } from "react-router-dom"
import { LoadingBar } from "components/core/loadingBar"
import { Filter as FilterIncidents } from "components/filters/filter"

// Utils
import classNames from "classnames"
import { calendar as utilsCalendar } from "utils/calendar"

// Icons
import { GoIssueOpened as OpenIcon, GoIssueClosed as ClosedIcon } from "react-icons/go"
import { BsCheckLg as CheckIcon } from "react-icons/bs"
import { BiMessage as MessageIcon } from "react-icons/bi"
import { TbProgressCheck as ProgressIcon } from "react-icons/tb"
import { GoCircle as DraftIcon } from "react-icons/go"

// Constants
import { to } from "constants/routes"

// Filters
import { useSyncFiltersWithUrl } from "hooks/useTableFilters"

// Hooks
import { useTranslation } from "react-i18next"
import { useIncidents } from "hooks/useIncidents"
import { useActiveOrg } from "hooks/useActiveOrg"

export function IncidentsList() {
    const { t } = useTranslation("common")
    const { incidents, loading, query, paging, setPage, setQuery, status: activeStatus, setStatus: setActiveStatus, filter:selectedFilter, setFilter:setSelectedFilter, filters, defaultFilter } = useIncidents()

    // Filters from URL
    const tableId = 'incidents';
    
    useSyncFiltersWithUrl(tableId, [
        {key: 'status', getter: () => activeStatus, setter: setActiveStatus, defaultValue: "open"},
        {key: 'filter', getter: () => selectedFilter, setter: (v:any)=>setSelectedFilter && setSelectedFilter(v), defaultValue: defaultFilter},
        {key: 'query', getter: () => query, setter: setQuery},
        {
            key: 'page',
            getter: () => paging?.page,
            setter: (page) => {if (page >= 1)setPage(parseInt(page))},
            defaultValue: 1
        }
    ]);

    const resetPage = () => {
        setPage(1)
    }
    
    return <div>
        <Container noPadding>
            <ContainerHeader className="px-4 py-3">
                <div className={classNames("flex items-center gap-y-1 flex-wrap text-sm text-container-foreground gap-x-4")}>
                    <p
                        onClick={() => {!loading && setActiveStatus("open"); resetPage()}}
                        className={classNames(activeStatus?.toLowerCase() === "open" && "text-white", "flex items-center", loading ? "hover:cursor-default" : "hover:cursor-pointer")}>
                        <OpenIcon className="mr-2 text-xl shrink-0" />{t("many-open")}
                    </p>
                    <p
                        onClick={() => {!loading && setActiveStatus("closed"); resetPage()}}
                        className={classNames(activeStatus?.toLowerCase() === "closed" && "text-white", "flex items-center", loading ? "hover:cursor-default" : "hover:cursor-pointer")}>
                        <CheckIcon className="mr-2 text-xl shrink-0" />{t("closed")}
                    </p>
                </div>
              
                <div className="flex items-center justify-end flex-1 ml-2">
                    <FilterIncidents filters={filters} selectedFilter={selectedFilter} setSelectedFilter={(v:any)=>{setSelectedFilter && setSelectedFilter(v); resetPage()}}/>
                </div>
            </ContainerHeader>
            <LoadingBar loading={incidents && loading}/>
            {
                incidents && incidents.map(({ id, name, ressource, acknowledged, progress, createdAt, status, shortDescription, description, messageCount }: Incident) => {
                    return <IncidentRow key={"incident-" + id}
                        id={id}
                        name={name}
                        createdAt={createdAt}
                        status={status}
                        ressource={ressource?.name}
                        acknowledged={acknowledged}
                        progress={progress}
                        messageCount={messageCount}
                        shortDescription={shortDescription}
                        description={description} />
                })
            }
            {
                loading && !incidents && <div className="flex items-center justify-center p-4 h-14"><Spinner /></div>
            }
            {
                incidents && incidents.length === 0 && <ListEmptyRow />
            }
        </Container>
    
        <Paginate {...paging} setPage={setPage} />

    </div>
}

export function IncidentRow({ id, name, status, messageCount, createdAt, progress, ressource, acknowledged }: Incident) {
    const { activeOrg } = useActiveOrg()
    const { t } = useTranslation("common")
    const dayDelta = utilsCalendar.getDatesDelta(new Date(), new Date(createdAt || ""))
    const yearDelta = utilsCalendar.getDatesYearDelta(new Date(), new Date(createdAt || ""));
    
    return <>
        <Link to={id ? to.incident(activeOrg.slug, id) : ""}>

            <div className="block p-2 px-4 hover:bg-background/30 hover:cursor-pointer">

                <div className="flex items-center">
                    {
                        status?.toLowerCase() === "open" ? !acknowledged ? <DraftIcon className="mr-2 text-xl text-primary-light shrink-0" />: <OpenIcon className="mr-2 text-xl text-primary-light shrink-0" /> : <ClosedIcon className="mr-2 text-xl text-purple-400 shrink-0" />
                    }
                    <div className="flex items-center justify-between w-full p-1 gap-x-1">
                        
                        <div className="flex justify-between sm:w-10/12">
                            <div className="flex flex-col font-medium">
                                <span className="text-sm leading-tight sm:text-base">{name}</span>
                                <div>
                                    {ressource &&  <span className="block text-xs font-semibold text-container-foreground sm:hidden">{ressource}</span>}
                                </div>
                                {createdAt && <span className="text-xs font-light text-container-foreground">{yearDelta >= 1 && dayDelta > 365 ? yearDelta === 1 ? t("opened-year-ago",{years:yearDelta}):t("opened-years-ago",{years:yearDelta}): dayDelta ===0 ? t("opened-today") : dayDelta === 1 ? t("opened-day-ago",{days:dayDelta}):t("opened-days-ago",{days:dayDelta})}</span>}
                            </div>
                        </div>
                        <div className={classNames("sm:flex flex-col hidden items-center justify-center mx-1 shrink-0 sm:w-2/12", !ressource && "hidden")}>
                            {
                                ressource && <div className="hidden sm:block">
                                    <span className="text-sm text-container-foreground">{ressource}</span>
                                </div>
                            }
                        </div>
                        <div className="flex flex-col sm:flex-row justify-end items-end sm:items-center gap-y-1 w-fit sm:w-32 gap-x-0.5">
                            {progress !== undefined && progress > 0  && <>
                                <div className={classNames("flex items-center sm:mr-2 gap-x-1 ", progress===100? "text-emerald-200 font-bold": "text-violet-300")}>
                                    <ProgressIcon className="text-lg " />
                                    <span className="text-sm leading-none ">{progress}%</span>
                                </div>
                            </>
                            }
                            {
                                messageCount && messageCount > 0 ? <>
                                    <div className="flex items-center gap-x-1">
                                        <MessageIcon className="mt-0.5 text-lg text-container-foreground" />
                                        <span className="text-sm leading-none text-container-foreground">{messageCount}</span>
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>

            </div>
        </Link>

    </>
}

function ListEmptyRow() {

    const { t } = useTranslation("common")

    return <>
        <div className="block p-3 hover:bg-background/30">
            <div className="flex items-center justify-between p-1 text-container-foreground">
                {t("no-incidents-message")}
            </div>
        </div>
    </>
}